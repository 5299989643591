<template>
  <WsMain>
    <WsReadSection
      :id="_id"
      :modelName="modelName"
      :label="label"
      :fields="fields"
      :leftFields="leftFields"
      :rightFields="rightFields"
      v-model="modelData.area"
    ></WsReadSection>
    <WsCrud
      v-if="modelData.area"
      class="mt-40"
      :modelName="$store.state.stone_model.system_subclass.modelName"
      :label="$store.state.stone_model.system_subclass.label"
      :fields="$store.state.stone_model.system_subclass.fields"
      :showFields="showFields.system_subclass"
      :infiniteScroll="true"
      :params="_params"
      :createData="_params"
      :updateData="_params"
    ></WsCrud>
  </WsMain>
</template>

<script>
import model from "@/__vue2stone_cms/models/system_class";
export default {
  data: () => ({
    modelName: model.modelName,
    label: model.label,
    fields: model.fields,
    leftFields: ["name"],
    rightFields: ["id", "sequence"],
    modelData: {
      area: null,
    },
    showFields: {
      system_subclass: ["id", "name", "area"],
    },
  }),

  computed: {
    _params() {
      return {
        area: this._id,
      };
    },
    _id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style>
</style>